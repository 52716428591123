.profile-content-container {
  h3 {
    // padding: 25px 25px 0px;
  }

  form {
    left: 0px;
    top: 0px;
  }

  textarea {
    resize: none;
    width: 778px;
    height: 121px;
    top: 21px;
    radius: 10px;
    background: #dddcdc;
    border: 0.5px solid #61481c;
    border-radius: 10px;
  }

  .restoInfo1 {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .restoInfo1 label {
    font-size: 15px;
  }

  .restoInfo1 input {
    background: #dddcdc;
    border: 0.5px solid #61481c;
    border-radius: 10px;
    width: 100%;
    padding: 1em 2em;
  }

  .restoInfo1 .restoInfo1-div {
    display: flex;
    flex-direction: column;
  }

  .restoInfo2 {
    display: grid;
    grid-template-columns: auto auto;
    width: 90%;
    padding: 20px;
  }

  .restoInfo2 label {
    font-size: 15px;
  }

  .restoInfo2 input {
    background: #dddcdc;
    border: 0.5px solid #61481c;
    border-radius: 10px;
    width: 80%;
    padding: 1em 2em;
  }

  .restoInfo2 .restoInfo2-div {
    display: flex;
    flex-direction: column;
  }

  .restoInfo1 input::placeholder {
    color: black;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .restoInfo1 textarea::placeholder {
    color: black;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    padding: 1em;
  }

  .restoInfo2 input::placeholder {
    color: black;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .buttons {
    float: right;
    margin-top: 20px;
    margin: 40px;
    display: flex;
    gap: 20px;

    text-transform: uppercase;
    cursor: pointer;
    font-size: 12px;
  }

  .buttons #editBtn {
    background: #d9d9d9;
    padding: 1em 5em;
    color: black;

    border: 0.5px solid rgba(97, 72, 28, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    text-decoration: none;
  }

  .buttons #saveBtn {
    background: #d9d9d9;
    padding: 1em 5em;
    color: black;

    border: 0.5px solid rgba(97, 72, 28, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    text-decoration: none;
  }
}
